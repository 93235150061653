import React from 'react'
import App from './App/App'

export default function Memory() {
    return (
        <div className="mt-20 w-screen">
            <h1 style={{ textAlign: 'center' }}>Jeu de mémoire</h1>
            <App />
        </div>
    )
}
