export default function Home() {
  return (
    <div className="flex flex-row justify-center w-screen">
      <div className="mt-20">
        <h1 style={{ textAlign: 'center' }}>Bienvenue sur mon espace d'applications web.</h1>
        <h2 style={{ textAlign: 'center' }}>Pour commencer, veuillez sélectionner une application dans le menu.</h2>
      </div>
    </div>
  )
}


