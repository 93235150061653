import React from 'react'
import App from './App/App.js'

export default function Hanged() {
    return (
        <div className="mt-20 w-screen" style={{ display: 'flex', justifyContent: 'center' }}>
            <App />
        </div>
    )
}
