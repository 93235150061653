import React from "react"
import Game from "./Game/Game"
import "./index.css"

export default function Morpion() {
    return (
        <div className="w-screen mt-20">
            <h1 style={{textAlign: 'center'}}>Jeu du morpion</h1>
            <Game />
        </div>
    )
}

