import React from "react";

export default function Footer() {
  return (
    <div
      className="fixed bottom-0 flex flex-row justify-center w-full h-6"
      style={{ backgroundColor: "#4b4e67", color: "white" }}
    >
      <span className="italic">
        © 2020 - 2021.{" "}
        <a
          className="link-blue"
          href="https://linkedin.com/in/enrick-payet"
          target="_blank"
          rel="noopener noreferrer"
        >
          @Enrick Payet
        </a>
      </span>
    </div>
  );
}
