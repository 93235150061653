import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import './index.css'

import Header from './components/Header'
import Navbar from './components/navbar/navbar'
import Footer from './components/Footer'

ReactDOM.render(
  <React.StrictMode>
    <div className="container">
      <Header />
      <Navbar />
      <Footer />
    </div>
  </React.StrictMode>,

  document.getElementById('root')
);

reportWebVitals();
