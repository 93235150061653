import React from 'react'
import './Letter.css';

const HIDDEN_SYMBOL = '__'

const Letter = ({ letter, feedback }) => (
  <div className={`letter ${feedback}`}>
    <span className="symbol" >
      {feedback === '_hidden' ? HIDDEN_SYMBOL : letter}
    </span>
  </div>
)

export default Letter
