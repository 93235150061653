import React, { Component } from "react";
import { HashRouter, Route, Link, Switch } from "react-router-dom";
import { bubble as Menu } from "react-burger-menu";
import { FaExternalLinkAlt, FaDotCircle } from "react-icons/fa";

import Home from "../../Home";
import Morpion from "../morpion/index";
import Hanged from "../hanged/index";
import Memory from "../memory/index";

let navStyles = {
  bmBurgerButton: {
    position: "fixed",
    width: "32px",
    height: "32px",
    left: "20px",
    top: "15px",
  },
  bmBurgerBars: {
    background: "black",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "black",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    top: 0,
    left: 0,
  },
  bmMenu: {
    background: "#4b4e67",
    padding: "0",
    fontSize: "1.15em",
    width: "100%",
  },
  bmMorphShape: {
    fill: "#4b4e67",
  },
  bmItemList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  bmItem: {
    display: "inline-block",
    color: "white",
    outline: "none",
    margin: "1em",
    listStyle: "none",
    textDecoration: "none",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.5)",
    top: 0,
    left: 0,
  },
};

export default class Example extends Component {
  render() {
    return (
      <HashRouter>
        <Menu className="navbar" styles={navStyles}>
          <ul>
            <li>
              <Link
                className="inline-flex"
                to="/"
                style={{ textDecoration: "none", color: "white" }}
              >
                <span>
                  <FaDotCircle
                    style={{
                      width: "20px",
                      marginTop: "0.4rem",
                      marginRight: "0.5rem",
                    }}
                  />
                </span>
                Accueil
              </Link>
            </li>
            <li>
              <Link
                className="inline-flex"
                to="/morpion"
                style={{ textDecoration: "none", color: "white" }}
              >
                <span>
                  <FaDotCircle
                    style={{
                      width: "20px",
                      marginTop: "0.4rem",
                      marginRight: "0.5rem",
                    }}
                  />
                </span>
                Jeu du morpion
              </Link>
            </li>
            <li>
              <Link
                className="inline-flex"
                to="/pendu"
                style={{ textDecoration: "none", color: "white" }}
              >
                <span>
                  <FaDotCircle
                    style={{
                      width: "20px",
                      marginTop: "0.4rem",
                      marginRight: "0.5rem",
                    }}
                  />
                </span>
                Jeu du pendu
              </Link>
            </li>
            <li>
              <Link
                className="inline-flex"
                to="/memoire"
                style={{ textDecoration: "none", color: "white" }}
              >
                <span>
                  <FaDotCircle
                    style={{
                      width: "20px",
                      marginTop: "0.4rem",
                      marginRight: "0.5rem",
                    }}
                  />
                </span>
                Jeu de mémoire
              </Link>
            </li>
            <div className="mt-8">
              <li>
                <a
                  className="inline-flex"
                  href="https://payet-portfolio.fr/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Accès portfolio
                  <span>
                    <FaExternalLinkAlt style={{ width: "10px" }} />
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="inline-flex"
                  href="https://blog.payet-portfolio.fr/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Accès espace blog
                  <span>
                    <FaExternalLinkAlt style={{ width: "10px" }} />
                  </span>
                </a>
              </li>
            </div>
          </ul>
        </Menu>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/morpion" component={Morpion} />
          <Route exact path="/pendu" component={Hanged} />
          <Route exact path="/memoire" component={Memory} />
          <Route exact component={Home} />
        </Switch>
      </HashRouter>
    );
  }
}
