import React from 'react'

export default function Header() {
    return (
        <div className="fixed top-0 flex flex-col w-full" style={{ backgroundColor: '#4b4e67', color: 'white' }}>
            <div className="flex flex-row justify-center items-center w-full h-16">
                <h1>Espace application</h1>
            </div>
        </div>
    )
}